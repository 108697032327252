import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

import Cards from "../../components/Cards";
import { getCards } from "../../services/CardServices";
import "../../styles/sidebar_cards.css";

const SidebarCards = () => {
  const [cards, setCards] = useState([]);
  const [currentCard, setCurrentCard] = useState(0);

  useEffect(() => {
    getCards()
      .then((response) => {
        setCards(response.data);
      })
      .catch((error) => {
        toast.error("ERROR! Please try again!");
      });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentCard((prevCard) =>
        prevCard === cards.length - 1 ? 0 : prevCard + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [cards.length]);

  const goToPrevCard = () => {
    setCurrentCard((prevCard) =>
      prevCard === 0 ? cards.length - 1 : prevCard - 1
    );
  };

  const goToNextCard = () => {
    setCurrentCard((prevCard) =>
      prevCard === cards.length - 1 ? 0 : prevCard + 1
    );
  };

  return (
    <section>
      <h2 className="text-lg font-semibold my-3">Card's Tops</h2>
      {cards.length > 0 && (
        <div
          className="carousel-container relative"
          style={{ position: "relative", overflow: "hidden" }}
        >
          <div
            className="arrow-container left-arrow"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              width: "20%",
              cursor: "pointer",
              zIndex: 1,
            }}
            onClick={goToPrevCard}
          >
            <button className="btn-style">❮</button>
          </div>
          <div
            className="carousel w-full relative"
            style={{ display: "flex", transition: "transform 0.5s" }}
          >
            {cards.map((card, index) => (
              <div
                key={index}
                className={`carousel-item relative w-full ${
                  index === currentCard ? "visible" : "hidden"
                }`}
              >
                <div className="carousel-card-wrapper relative">
                  <Cards key={index} card={card} />
                </div>
              </div>
            ))}
          </div>
          <div
            className="arrow-container right-arrow"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              width: "20%",
              cursor: "pointer",
              zIndex: 1,
            }}
            onClick={goToNextCard}
          >
            <button className="btn-style">❯</button>
          </div>
        </div>
      )}
    </section>
  );
};

export default SidebarCards;
