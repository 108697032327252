import React from "react";

import "../../styles/privacy_policy.css";

const Privacy = () => {
  return (
    <div className="table-scroll text-md text-gray-700 overflow-y-auto p-2">
      <p>
        <strong>Privacy Policy</strong>
      </p>
      <p>Last updated: April 20, 2024</p>
      <p>
        Welcome to our website, and I invite you to become familiar with these
        terms before providing your personal data on it.
      </p>
      <p>
        Choose Your Country ("we," "our," or "us") is committed to protecting
        your privacy. This Privacy Policy explains how Choose Your Country
        collects, uses, and discloses your personal information.
      </p>
      <p>
        This Privacy Policy applies to our website, www.choose-your-country.com,
        and its associated subdomains (collectively, our "Service"). By
        accessing or using our Service, you indicate that you have read,
        understood, and agreed to our collection, storage, use, and disclosure
        of your personal information as described in this Privacy Policy and in
        our Terms of Service.
      </p>
      <p>
        <strong>Definitions and Key Terms</strong>
      </p>
      <p>
        To help explain things as clearly as possible in this Privacy Policy,
        whenever any of these terms are referenced, they are strictly defined as
        follows:
      </p>
      <p>
        Cookie: a small amount of data generated by a website and saved by your
        web browser. It is used to identify your browser, provide analytics, and
        remember information about you, such as your language preference or
        login information.
      </p>
      <p>
        Company: when this policy mentions "Company," "we," "us," or "our," it
        refers to Choose Your Country, which is responsible for your information
        under this Privacy Policy.
      </p>
      <p>
        Platform: Choose Your Country's public-facing internet website, web
        application, or digital application.
      </p>
      <p>
        Country: where Choose Your Country is located or the owners/founders of
        Choose Your Country are located; in this case, Spain.
      </p>
      <p>
        Client: refers to the company, organization, or person who signs up to
        use the Choose Your Country Service to manage relationships with their
        consumers or service users.
      </p>
      <p>
        Device: any internet-connected device, such as a phone, tablet,
        computer, or any other device that can be used to visit Choose Your
        Country and use the services.
      </p>
      <p>
        IP Address: each internet-connected device is assigned a number known as
        an Internet Protocol (IP) address. These numbers are typically assigned
        in geographic blocks. An IP address can often be used to identify the
        location from which a device is connecting to the internet.
      </p>
      <p>
        Personnel: refers to those individuals who are employed by Choose Your
        Country or are under contract to perform a service on behalf of one of
        the parties.
      </p>
      <p>
        Personal Data: any information that directly, indirectly, or in
        connection with other information, including a personal identification
        number, allows the identification of a natural person.
      </p>
      <p>
        Service: refers to the service provided by Choose Your Country as
        described in the Terms and on this platform.
      </p>
      <p>
        Third-party: refers to advertisers, contest sponsors, promotional and
        marketing partners, and others who provide our content or whose products
        or services we believe may interest you.
      </p>
      <p>
        Website: the Choose Your Country site that can be accessed via this URL:
        www.choose-your-country.com.
      </p>
      <p>
        You: a person or entity that is registered with Choose Your Country to
        use the Services.
      </p>
      <p>
        <strong>Automatically Collected Information</strong>
      </p>
      <p>
        There is certain information, such as your Internet Protocol (IP)
        address and/or browser and device characteristics, that is collected
        automatically when you visit our platform. This information is used to
        connect your computer to the internet. Other automatically collected
        information could be a login, email address, password, computer and
        connection information, such as browser plug-in types and versions and
        time zone settings, operating systems, and platforms, purchase history
        (sometimes we add similar information from other users), the full
        Uniform Resource Locator (URL) clickstream to, through, and from our
        website (including date and time); cookie number; parts of the site you
        viewed or searched for; and the phone number used to call our customer
        service. We may also use browser data such as cookies, Flash cookies
        (also known as Flash Local Shared Objects), or similar data in certain
        parts of our website for fraud prevention and other purposes. During
        your visits, we may use software tools such as JavaScript to measure and
        collect session information, including page response times, download
        errors, length of visits to certain pages, page interaction information
        (such as scrolling, clicks, and mouseovers), and methods used to browse
        away from the page. We may also collect technical information to help us
        identify your device for fraud prevention and diagnostic purposes.
      </p>
      <p>
        We automatically collect certain information when you visit, use, or
        navigate the platform. This information does not reveal your specific
        identity (like your name or contact information), but may include usage
        and device information, such as your IP address, browser and device
        characteristics, operating system, language preferences, referring URLs,
        device name, country, location, information about how and when you use
        our information, and other technical information. This information is
        primarily needed to maintain the security and operation of our platform,
        and for our internal analytics and reporting purposes.
      </p>
      <p>
        <strong>How do we use the information we collect?</strong>
      </p>
      <p>
        Any information we collect from you may be used in one of the following
        ways:
      </p>
      <p>
        To personalize your experience (your information helps us to better
        respond to your individual needs).
      </p>
      <p>
        To improve our platform (we continually strive to improve what our
        platform offers based on the information and feedback we receive from
        you).
      </p>
      <p>
        To improve customer service (your information helps us to more
        effectively respond to your customer service requests and support
        needs).
      </p>
      <p>To process transactions.</p>
      <p>To administer a contest, promotion, survey or other site feature.</p>
      <p>To send periodic emails.</p>
      <p>
        <strong>
          When does Choose Your Country use end user information from third
          parties?
        </strong>
      </p>
      <p>
        Choose Your Country will collect end user data necessary to provide
        Choose Your Country services to our clients.
      </p>
      <p>
        End users may voluntarily provide us with information they have made
        available on social media websites. If you provide us with such
        information, we may collect publicly available information from the
        social media websites you have indicated. You can control how much
        information social media websites make public by visiting these websites
        and changing your privacy settings.
      </p>
      <p>
        <strong>
          When does Choose Your Country use third-party client information?
        </strong>
      </p>
      <p>
        We receive information from third parties when you communicate with us.
        For example, when you provide us with your email address to show
        interest in becoming a Choose Your Country client, we receive
        information from a third party that provides automatic fraud detection
        services to Choose Your Country. Occasionally, we also gather
        information that social media websites make public by visiting these
        websites and changing your privacy settings.
      </p>
      <p>
        <strong>
          Do we share the information we collect with third parties?
        </strong>
      </p>
      <p>
        We may share the information we collect, both personal and non-personal,
        with third parties such as advertisers, contest sponsors, marketing and
        promotional partners, and others who provide our content or whose
        products or services we believe may interest you. We may also share it
        with our current and future affiliated companies and business partners,
        and if we are involved in a merger, sale of assets or other business
        reorganization, we may also share or transfer your personal and
        non-personal information to our successors in interest.
      </p>
      <p>
        We may engage trusted third-party service providers to perform functions
        and provide services to us, such as hosting and maintaining our servers
        and platform, database storage and management, email management, storage
        marketing, data processing, credit cards, service and fulfillment of
        orders for products and services that you may purchase through the
        platform. We may share your personal information, and possibly some
        non-personal information, with these third parties to enable them to
        perform these services for us and you.
      </p>
      <p>
        We may share portions of our log file data, including IP addresses, for
        analytics purposes with third parties such as web analytics partners,
        app developers, and advertising networks. If your IP address is shared,
        it may be used to estimate general location and other technological
        data, such as connection speed, whether you have visited the Platform in
        a shared location, and the type of device used to visit the Platform.
        They may aggregate information about our advertising and what you see on
        the platform and then provide audits, investigations and reports for us
        and our advertisers.
      </p>
      <p>
        We may also disclose personal and non-personal information about you to
        government, law enforcement officials or private third parties as we
        believe, in our sole discretion, necessary or appropriate to respond to
        claims, legal process (including subpoenas), to protect our rights and
        interests or those of a third party, the safety of the public or any
        person, to prevent or stop any illegal, unethical or legally actionable
        activity, or to comply with applicable court orders, laws, rules and
        regulations.
      </p>
      <p>
        <strong>
          Where and when is information collected from customers and end users?
        </strong>
      </p>
      <p>
        Choose Your Country will collect the personal information that you
        submit to us. We may also receive personal information about you from
        third parties as described above.
      </p>
      <p>
        <strong>Could my information be transferred to other countries?</strong>
      </p>
      <p>
        Choose Your Country is incorporated in Spain. Information collected
        through our website, through direct interactions with you, or through
        the use of our support services may be transferred from time to time to
        our offices or staff, or to third parties located around the world, and
        may be viewed and hosted anywhere in the world, including countries that
        may not have generally applicable laws regulating the use and transfer
        of such data. To the fullest extent permitted by applicable law, by
        using any of the above, you voluntarily consent to the cross-border
        transfer and hosting of such information.
      </p>
      <p>
        <strong>
          Is the information collected through the Choose Your Country Service
          secure?
        </strong>
      </p>
      <p>
        We take precautions to protect the security of your information. We have
        physical, electronic and administrative procedures in place to help
        safeguard, prevent unauthorized access, maintain data security and
        correctly use your information. However, neither people nor security
        systems are infallible, including encryption systems. Additionally,
        people may commit intentional crimes, make mistakes, or fail to follow
        policies. Therefore, although we use reasonable efforts to protect your
        personal information, we cannot guarantee its absolute security. If
        applicable law imposes any unwaivable duty to protect your personal
        information, you agree that intentional misconduct will be the standards
        used to measure our compliance with that duty.
      </p>
      <p>
        <strong>Can I update or correct my information?</strong>
      </p>
      <p>
        The rights you have to request updates or corrections to the information
        Choose Your Country collects depend on your relationship with Choose
        Your Country. Staff may update or correct their information as detailed
        in our internal company employment policies. Customers have the right to
        request restriction of certain uses and disclosures of personally
        identifiable information as follows. You may contact us to (1) update or
        correct your personally identifiable information, (2) change your
        preferences regarding communications and other information you receive
        from us, or (3) delete personally identifiable information maintained
        about you in our system (subject to the following paragraph), canceling
        your account. Such updates, corrections, changes and deletions will have
        no effect on other information we maintain or information we have
        provided to third parties in accordance with this Privacy Policy prior
        to such update, correction, change or deletion. To protect your privacy
        and security, we may take reasonable steps (such as requiring a unique
        password) to verify your identity before granting you access to your
        profile or making corrections. You are responsible for maintaining the
        secrecy of your unique password and account information at all times.
      </p>
      <p>
        You should keep in mind that it is not technologically possible to
        delete each and every record of the information you have provided to us
        from our system. The need to back up our systems to protect information
        from inadvertent loss means that a copy of your information may exist in
        a form that cannot be erased and will be difficult or impossible for us
        to locate. Immediately upon receipt of your request, all personal
        information stored in the databases we actively use and other easily
        searchable media will be updated, corrected, changed or deleted, as
        appropriate, as soon as and to the extent reasonably and technically
        feasible. possible. If you are an end user and would like to update,
        delete or receive any information we have about you, you may do so by
        contacting the organization of which you are a customer.
      </p>
      <p>
        <strong>Business sale</strong>
      </p>
      <p>
        We reserve the right to transfer information to a third party in the
        event of a sale, merger or other transfer of all or substantially all of
        the assets of Choose Your Country or any of its corporate affiliates (as
        defined in this document), or the Choose Your Country portion or any of
        its Corporate affiliates with whom the Service relates, or in the In the
        event we discontinue our business or file a petition or we have filed a
        petition against us in case bankruptcy, reorganization or similar
        proceeding, provided that the third party agrees to adhere to the terms
        of this Privacy Policy privacy.
      </p>
      <p>
        <strong>Affiliates</strong>
      </p>
      <p>
        We may disclose information (including personal information) about you
        to our corporate affiliates. For purposes of this Privacy Policy,
        "Corporate Affiliate" means any person or entity that directly or
        indirectly controls, is controlled by, or is under common control with
        Choose Your Country, whether by ownership or otherwise. Any information
        relating to you that we provide to our corporate affiliates will be
        treated by such corporate affiliates in accordance with the terms of
        this privacy policy.
      </p>
      <p>
        <strong>How long do we keep your information?</strong>
      </p>
      <p>
        We retain your information only as long as we need it to respond to your
        inquiry through our contact form and provide you with the information
        requested and fulfill the purposes described in this policy. This is
        also the case for anyone with whom we share your information and who
        performs services on our behalf. When we no longer need to use your
        information and it is no longer necessary for us to retain it to comply
        with our legal or regulatory obligations, we will delete it from our
        systems or depersonalize it so that we cannot identify you.
      </p>
      <p>
        <strong>How do we protect your information?</strong>
      </p>
      <p>
        We implement a variety of security measures to maintain the security of
        your personal information when you place an order, enter, submit or
        access your personal information. We offer the use of a secure server.
        All confidential/credit information supplied is transmitted via Secure
        Socket Layer (SSL) technology and then encrypted into our payment
        gateway providers database only to be accessible by those authorized
        with special access rights to such systems, and must keep information
        confidential. After a transaction, your private information (credit
        cards, social security numbers, finances, etc.) is never archived.
        However, we cannot guarantee the absolute security of the information
        you transmit to Choose Your Country or guarantee that your information
        on the service will not be accessed, disclosed, altered or destroyed by
        a breach of any of our physical, technical or management safeguards.
      </p>
      <p>
        <strong>Governing Law</strong>
      </p>
      <p>
        This Privacy Policy is governed by the laws of Spain without regard to
        its conflict of laws provisions. You consent to the exclusive
        jurisdiction of the courts in connection with any action or dispute
        arising between the parties under or in connection with this Privacy
        Policy, except for those persons who may have the right to bring claims
        under the Privacy Shield or the Swiss-American framework. The laws of
        Spain, excluding its conflicts of laws, will govern this Agreement and
        your use of the Platform. Your use of the Platform may also be subject
        to other local, state, national or international laws. By using Choose
        Your Country or contacting us directly, you signify your acceptance of
        this Privacy Policy. If you do not agree to this Privacy Policy, you
        should not interact with our website or use our services. Your continued
        use of the Website, direct interaction with us, or following the posting
        of changes to this Privacy Policy that do not materially affect the use
        or disclosure of your personal information will mean that you accept
        those changes. Choose Your Country has adapted this website to the
        requirements of Organic Law 15/1999, of December 13, on the Protection
        of Personal Data (LOPD), and Royal Decree 1720/2007, of December 21,
        known as the Regulations for the development of the LOPD. It also
        complies with Regulation (EU) 2016/679 of the European Parliament and of
        the Council of April 27, 2016 regarding the protection of natural
        persons (RGPD), as well as Law 34/2002, of July 11, Information Society
        Services and Electronic Commerce (LSSICE or LSSI).
      </p>
      <p>
        <strong>Your consent</strong>
      </p>
      <p>
        We have updated our Privacy Policy to give you full transparency about
        what is set when you visit our site and how it is used. By using our
        platform, registering an account or making a purchase, you hereby
        consent to our Privacy Policy and agree to its terms.
      </p>
      <p>
        <strong>Links to other Websites</strong>
      </p>
      <p>
        This Privacy Policy applies only to the Services. The Services may
        contain links to other websites that Choose Your Country does not
        operate or control. We are not responsible for the content, accuracy or
        opinions expressed in such websites, and such websites are not
        investigated, monitored or checked for accuracy or completeness. Please
        remember that when you use a link to go from the Services to another
        website, our Privacy Policy is no longer in effect. Your browsing and
        interaction on any other website, including those linked to on our
        platform, is subject to that website's own rules and policies. These
        third parties may use their own cookies or other methods to collect
        information about you.
      </p>
      <p>
        <strong>Donations</strong>
      </p>
      <p>
        We may provide the option for users to make donations to support our
        website. These donations are facilitated through third-party platforms
        such as PayPal or Buy Me a Coffee. When making a donation, users may be
        required to provide personal information directly to these third-party
        services. We do not collect or store any payment information provided
        during the donation process. Please note that when making a donation
        through these third-party platforms, your information will be subject to
        their respective privacy policies and practices. We encourage you to
        review the privacy policies of these services before providing any
        personal information.
      </p>
      <p>
        <strong>Cookies</strong>
      </p>
      <p>
        Choose Your Country uses "cookies" to identify the areas of our website
        that you have visited. A cookie is a small piece of data that your web
        browser stores on your computer or mobile device. We use cookies to
        improve the performance and functionality of our platform, but they are
        not essential for its use. However, without these cookies, certain
        features, such as videos, may not be available or you may be required to
        enter your login details each time you visit the platform as we would
        not be able to remember that you had previously logged in. . Most web
        browsers can be configured to disable the use of cookies. However, if
        you disable cookies, you may not be able to access the functionality of
        our website correctly or at all. We never place personally identifiable
        information in cookies.
      </p>
      <p>
        <strong>Changes to our Privacy Policy</strong>
      </p>
      <p>
        We may change our Service and our policies, and we may need to make
        changes to this Privacy Policy so that they accurately reflect our
        Service and our policies. Unless otherwise required by law, we will
        notify you (for example, through our Service) before we make changes to
        this Privacy Policy and will give you the opportunity to review them
        before they become effective. Then, if you continue to use the Service,
        you will be subject to the updated Privacy Policy. If you do not wish to
        accept this or any updated Privacy Policy, you may delete your account.
      </p>
      <p>
        <strong>Third Party services</strong>
      </p>
      <p>
        We may display, include or make available third party content (including
        data, information, application and other product services) or provide
        links to third party websites or services (“Third Party Services”). You
        acknowledge and agree that Choose Your Country shall not be responsible
        or liable for any Third Party Services, including their accuracy,
        completeness, timeliness, validity, copyright compliance, legality,
        decency, quality or any other aspect thereof. Choose Your Country does
        not assume and shall not have any obligation or liability to you or any
        person or entity for any Third Party Services. Third-party services and
        links to them are provided solely as a convenience to you and you access
        and use them entirely at your own risk and subject to the terms and
        conditions of such third parties. Information about the General Data
        Protection Regulation (GDPR) We may collect and use information about
        you if you are from the European Economic Area (EEA), and in this
        section of our Privacy Policy we will explain exactly how and why this
        data is collected, and how we keep it protected against replication or
        incorrect use.
      </p>
      <p>
        <strong>What is GDPR?</strong>
      </p>
      <p>
        GDPR is an EU-wide data protection and privacy law that regulates how
        companies protect the data of EU residents and improves the control that
        EU residents have over their personal data.
      </p>
      <p>
        <strong>What is Personal Data?</strong>
      </p>
      <p>
        Any data that relates to an identifiable or identifiable individual. The
        GDPR covers a broad spectrum of information that could be used alone or
        in combination with other pieces of information to identify an
        individual. Personal data goes beyond a person's name or email address.
        Some examples include financial information, political opinions, genetic
        data, biometric data, IP addresses, physical address, sexual orientation
        and ethnicity. Data protection principles include requirements such as:
      </p>
      <p>
        Personal data collected must be processed fairly, lawfully and
        transparently and must only be used in a way that a person would
        reasonably expect.
      </p>
      <p>
        Personal data should only be collected to fulfill a specific purpose and
        should only be used for that purpose. Organizations must specify why
        they need the personal data when they collect it.
      </p>
      <p>
        Personal data should not be kept longer than necessary to fulfill its
        purpose. Individuals covered by the GDPR have the right to access their
        own personal data. They can also request a copy of their data and that
        their data be updated, deleted, restricted or moved to another
        organization.
      </p>
      <p>
        <strong>Why is GDPR important?</strong>
      </p>
      <p>
        GDPR adds some new requirements regarding how companies must protect the
        personal data of individuals they collect and process. It also raises
        the stakes for compliance by increasing enforcement and imposing higher
        fines for noncompliance. Beyond these facts, it is simply the right
        thing to do. At Choose Your Country we firmly believe that the privacy
        of your data is very important and we already have robust security and
        privacy practices in place that go beyond the requirements of this new
        regulation.
      </p>
      <p>
        <strong>
          Individual rights of the interested party: access, portability and
          deletion of data
        </strong>
      </p>
      <p>
        We are committed to helping our clients comply with the data subject
        rights requirements of the GDPR. Choose Your Country processes or stores
        all personal data with DPA compliant and fully vetted providers. We
        store all conversations and personal data as long as the application is
        active and its deletion is not requested by the interested party, unless
        their account is deleted. In which case, we delete all data in
        accordance with our Terms of Service and Privacy Policy, but we will not
        retain it for longer than 60 days. We are aware that if you work with EU
        clients, you must be able to provide them with the ability to access,
        update, retrieve and delete personal data. We have been set up as
        self-service from the beginning and have always given you access to your
        data and your customers' data. Our customer support team is here to
        answer any questions you may have about working with the API.
      </p>
      <p>
        <strong>Contact us</strong>
      </p>
      <p>
        If you have any questions about this Privacy Policy, you can contact us:
      </p>
      <ul>
        <li>By email: proyectosoftwaresa@gmail.com</li>
      </ul>
    </div>
  );
};

export default Privacy;
