import React from "react";

import CookieTable from "./CookieTable";
import "../../styles/privacy_policy.css";

const Cookies = () => {
  return (
    <div className="table-scroll text-md text-gray-700 overflow-y-auto p-2">
      <p>
        <strong>Cookies Policy</strong>
      </p>
      <p>Last updated: April 20, 2024</p>
      <p>
        <strong>Information about cookies</strong>
      </p>
      <p>
        In accordance with Law 34/2002, of July 11, on information society
        services and electronic commerce (LSSI), in relation to Regulation (EU)
        2016/679 of the European Parliament and of the Council, of 27 April
        2016, General Data Protection (GDPR) and Organic Law 3/2018, of December
        5, on Data Protection and Guarantee of Digital Rights (LOPDGDD), it is
        mandatory to obtain the express consent of the user of all web pages
        that use dispensable cookies, before you navigate through them.
      </p>
      <p>
        <strong>What are cookies?</strong>
      </p>
      <p>
        Cookies and other similar technologies, such as local shared objects,
        flashcookies or pixels, are tools used by Web servers to store and
        retrieve information about their visitors, as well as to provide proper
        functioning of the site. Through the use of these devices, the Web
        server is allowed to remember some data concerning the user, such as
        their preferences for viewing the pages of that server, name and
        password, products that interest them most, etc.
      </p>
      <p>
        <strong>Cookies affected by regulations and excepted cookies</strong>
      </p>
      <p>
        According to the EU directive, the cookies that require informed consent
        from the user are analytical cookies and advertising and affiliation
        cookies, with the exception of those of a technical nature and those
        necessary for the operation of the website or the provision of services.
        expressly requested by the user.
      </p>
      <p>
        <strong>Types of cookies</strong>
      </p>
      <p>According to the purpose</p>
      <p>
        Technical and functional cookies: these are those that allow the user to
        navigate through a website, platform or application and use the
        different options or services that exist therein.
      </p>
      <p>
        Analytical cookies: these are those that allow the person responsible
        for them to monitor and analyse the behaviour of the users of the
        websites to which they are linked. The information collected through
        this type of cookies is used to measure the activity of the websites,
        applications or platforms and to prepare navigation profiles of the
        users of said sites, applications and platforms, in order to introduce
        improvements based on the analysis of usage data made by users of the
        service.
      </p>
      <p>
        Advertising cookies: are those that allow the management, in the most
        efficient way possible, of the advertising spaces that, where
        appropriate, the editor has included in a web page, application or
        platform from which the requested service is provided based on criteria.
        such as edited content or the frequency at which ads are shown.
      </p>
      <p>
        Behavioural advertising cookies: they collect information about the
        user's personal preferences and choices (retargeting) to allow the
        management, in the most efficient way possible, of the advertising
        spaces that, where appropriate, the editor has included on a website,
        application. or platform from which the requested service is provided.
      </p>
      <p>
        Social Cookies: These are set by social media platforms on the Services
        to allow you to share content with your friends and networks. Social
        media platforms have the ability to track your online activity outside
        of the Services. This may affect the content and messages you see on
        other services you visit.
      </p>
      <p>
        Affiliate cookies: allow you to track visits from other websites, with
        which the website establishes an affiliation contract (affiliate
        companies).
      </p>
      <p>
        Security cookies: they store encrypted information to prevent the data
        stored in them from being vulnerable to malicious attacks from third
        parties.
      </p>
      <p>According to the property</p>
      <p>
        Own cookies: are those that are sent to the user's terminal equipment
        from a computer or domain managed by the editor itself and from which
        the service requested by the user is provided.
      </p>
      <p>
        Third-party cookies: are those that are sent to the user's terminal
        equipment from a computer or domain that is not managed by the editor,
        but by another entity that processes the data obtained through cookies.
      </p>
      <p>According to the conservation period</p>
      <p>
        Session cookies: are a type of cookies designed to collect and store
        data while the user accesses a web page.
      </p>
      <p></p>
      <p>
        <strong>Information about cookies</strong>
      </p>
      <p>
        Persistent cookies: are a type of cookie in which the data continues to
        be stored in the terminal and can be accessed and processed for a period
        defined by the person responsible for the cookie, which can range from a
        few minutes to several years.
      </p>
      <p>
        <strong>Processing of personal data</strong>
      </p>
      <p>
        Choose Your Country is the Controller of the personal data of the
        Interested Party and informs you that these data will be processed in
        accordance with the provisions of Regulation (EU) 2016/679, of April 27,
        2016 (GDPR), therefore You are provided with the following treatment
        information:
      </p>
      <p>
        Purposes of processing: as specified in the cookies section used on this
        website.
      </p>
      <p>
        Legitimation of the processing: except in cases where it is necessary
        for browsing the website, by consent of the interested party (art. 6.1
        GDPR).
      </p>
      <p>
        Data conservation criteria: as specified in the section on cookies used
        on the website.
      </p>
      <p>
        Communication of data: data will not be communicated to third parties,
        except in cookies owned by third parties or by legal obligation.
      </p>
      <p>Rights that assist the Interested Party:</p>
      <p>Right to withdraw consent at any time.</p>
      <p>
        Right of access, rectification, portability and deletion of your data,
        and limitation or opposition to its processing.
      </p>
      <p>
        Right to file a claim with the Control Authority (www.aepd.es) if you
        consider that the treatment does not comply with current regulations.
      </p>
      <p>
        <strong>Cookies used on this website</strong>
      </p>
      <CookieTable
        tableFiles={[
          "TechnicalCookies.csv",
          "AnalyticsCookies.csv",
          "AdvertisingCookies.csv",
        ]}
        titles={["Technical and functional", "Analytics", "Advertising"]}
      />
      <p>
        <strong>Third party cookies</strong>
      </p>
      <table className="table-auto w-full">
        <thead className="bg-gray-100">
          <tr className="table-active">
            <th className="p-4 text-center">Editor</th>
            <th className="p-4 text-center">Privacy Policy</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-content">Google Analytics</td>
            <td className="table-content">
              https://privacy.google.com/take-control.html
            </td>
          </tr>
          <tr>
            <td className="table-content">Google</td>
            <td className="table-content">
              https://privacy.google.com/take-control.html
            </td>
          </tr>
          <tr>
            <td className="table-content">Google</td>
            <td className="table-content">
              https://safety.google/privacy/privacy-controls/
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table-auto w-full pt-4">
        <thead className="bg-gray-100">
          <tr className="table-active">
            <th className="p-4 text-center"></th>
            <th className="p-4 text-center">
              How to manage cookies from the browser
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-content">Delete cookies from the device</td>
            <td className="table-content">
              Cookies already on a device can be deleted by clearing the browser
              history, which deletes cookies from all websites visited. However,
              some saved information (for example, login data or website
              preferences) may also be lost.
            </td>
          </tr>
          <tr>
            <td className="table-content">Manage site-specific cookies</td>
            <td className="table-content">
              To have more precise control over site-specific cookies, users can
              adjust their privacy and cookie settings in the browser.
            </td>
          </tr>
          <tr>
            <td className="table-content">Block cookies</td>
            <td className="table-content">
              Although most modern browsers can be configured to prevent cookies
              from being placed on devices, this may require you to manually
              adjust certain preferences each time you visit a site or page.
              Additionally, some services and features may not work correctly
              (for example, profile logins).
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table-auto w-full pt-4">
        <thead className="bg-gray-100">
          <tr className="table-active">
            <th className="p-4 text-center"></th>
            <th className="p-4 text-center">
              How to delete cookies from the most common browsers
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-content">Chrome</td>
            <td className="table-content">
              https://support.google.com/chrome/answer/95647?hl=en
            </td>
          </tr>
          <tr>
            <td className="table-content">Edge</td>
            <td className="table-content">
              https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
            </td>
          </tr>
          <tr>
            <td className="table-content">Explorer</td>
            <td className="table-content">
              https://support.microsoft.com/es-es/help/278835/how-to-delete-cookie-files-in-internet-explorer
            </td>
          </tr>
          <tr>
            <td className="table-content">Firefox</td>
            <td className="table-content">
              https://www.mozilla.org/en-US/privacy/websites/#cookies
            </td>
          </tr>
          <tr>
            <td className="table-content">Safari</td>
            <td className="table-content">
              https://support.apple.com/en-gb/guide/safari/sfri11471/mac
            </td>
          </tr>
          <tr>
            <td className="table-content">Opera</td>
            <td className="table-content">
              https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        If you have any questions about this Cookies Policy, you can contact us:
      </p>
      <ul>
        <li>By email: proyectosoftwaresa@gmail.com</li>
      </ul>
    </div>
  );
};

export default Cookies;
