import React from "react";

import GlobalFilter from "./GlobalFilter";
import "../../styles/table_header.css";

const TableHeader = ({ text, filter, setFilter }) => {
  return (
    <div className="header-containter">
      <div className="header-container2">
        <div className="titleTable">{text}</div>
        <div>
          <GlobalFilter filter={filter} setFilter={setFilter} />
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
