import React, { useState, useEffect } from "react";
import Cookie from "js-cookie";
import { FaPaypal } from "react-icons/fa";
import { Switch } from "@headlessui/react";

import "../../styles/cookiebanner.css";
import {
  enableGoogleAnalytics,
  enableGoogleAdsense,
  useTrackGoogleAnalytics,
} from "./GoogleServices";

const debug = false;

const CookieBanner = () => {
  const [visible, setVisible] = useState(false);
  const [showConfiguration, setShowConfiguration] = useState(false);
  const [consent, setConsent] = useState({
    necessary: true,
    statistics: true,
    advertising: true,
  });

  useTrackGoogleAnalytics();

  useEffect(() => {
    if (Cookie.get("consent") === undefined || debug) {
      document.body.style.overflow = "hidden";
      setVisible(true);
    }

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, []);

  useEffect(() => {
    if (
      window.location.href.includes("privacy-policy") ||
      window.location.href.includes("cookies-policy")
    ) {
      document.body.style.overflow = "scroll";
    } else if (Cookie.get("consent") === undefined || debug) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "scroll";
    };
  }, [visible]);

  const accept = () => {
    Cookie.set("consent", JSON.stringify(consent), {
      sameSite: "strict",
      expires: 365,
    });
    if (consent.statistics) {
      enableGoogleAnalytics();
    } else {
      Cookie.remove("_ga");
      Cookie.remove("_gat");
      Cookie.remove("_gid");
    }
    if (consent.advertising) {
      enableGoogleAdsense();
    }

    setVisible(false);
    document.body.style.overflow = "scroll";
  };

  const configure = () => {
    setShowConfiguration(true);
  };

  if (
    !visible ||
    window.location.href.includes("privacy-policy") ||
    window.location.href.includes("cookies-policy")
  ) {
    return null;
  }

  const handleConsentChange = (category, value) => {
    setConsent((prevState) => ({
      ...prevState,
      [category]: value,
    }));
  };

  return (
    <>
      <div className="fixed z-50 right-0 bottom-0 top-0 left-0 bg-black bg-opacity-50"></div>
      <div className="cookies-modal-container">
        <div className="prompt-modal-content">
          <div className="cookies-title-container">
            <img
              alt="Choose Your Country"
              src="images/Logo_Choose_your_contry.png"
              width="40"
              height="40"
              title="Choose Your Country"
              className="profile u-photo"
            />
            <h1 className="ml-1">Hi, welcome on Choose Your Country 👋</h1>
          </div>
          <div>
            <div className="cookies-text-container">
              <div>
                <div>
                  <p>
                    You can easily support me by accepting optional
                    (third-party) cookies. These cookies will help with the
                    following:
                  </p>
                  <ul>
                    <li>
                      ✅ Collect audience interaction data and site statistics.
                    </li>
                    <li>
                      ✅ Deliver advertisements and measure the effectiveness of
                      advertisements.
                    </li>
                  </ul>
                </div>
                <div className="mt-4">
                  <p>
                    If you do not want to share your data with third parties but
                    still want to support please visit
                    <span className="inline-block">
                      <a
                        href="https://www.paypal.com/paypalme/chooseyourcountry?country.x=ES&locale.x=es_ES"
                        target="_blank"
                        rel="noreferrer"
                        className="border-b border-amber-300 hover:border-transparent text-amber-400 flex items-center ml-1"
                      >
                        PayPal <FaPaypal className="paypal ml-1" />
                      </a>
                    </span>
                  </p>
                  <p>
                    For more information about cookies and how they are used
                    please have a look at the
                    <a
                      href="privacy-policy"
                      className="underline decoration-solid hover:no-underline mx-1"
                    >
                      Privacy Policy
                    </a>
                    and
                    <a
                      href="cookies-policy"
                      className="underline decoration-solid hover:no-underline ml-1"
                    >
                      Cookies Policy
                    </a>
                    .
                  </p>
                </div>
              </div>
              <div className="m-4 flex justify-between flex-col sm:flex-row gap-2">
                <button onClick={configure} className="b-below-to-close">
                  Configure
                </button>
                <button onClick={accept} className="primary-button">
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfiguration && (
        <div className="cookies-modal-container">
          <div className="prompt-modal-content">
            <div className="cookies-title-container">
              <img
                alt="Choose Your Country"
                src="images/Logo_Choose_your_contry.png"
                width="40"
                height="40"
                title="Choose Your Country"
                className="profile u-photo"
              />
              <h1 className="ml-1">This website uses cookies 🍪</h1>
            </div>
            <div>
              <div className="cookies-text-container">
                <div>
                  <p>
                    We use cookies to personalise content and ads, to provide
                    social media features and to analyse our traffic. We also
                    share information about your use of our site with our social
                    media, advertising and analytics partners who may combine it
                    with other information that you’ve provided to them or that
                    they’ve collected from your use of their services.
                  </p>
                  <div className="m-4 flex items-center justify-center space-x-4">
                    <Switch.Group as="div" className="switch-group">
                      <Switch.Label className="switch-label font-semibold">
                        Necessary
                      </Switch.Label>
                      <Switch
                        checked={consent.necessary}
                        disabled
                        className="bg-blue-200 relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none"
                      >
                        <span className="sr-only">Necessary</span>
                        <span className="translate-x-6 inline-block w-4 h-4 transform bg-white rounded-full" />
                      </Switch>
                    </Switch.Group>
                    <Switch.Group as="div" className="switch-group">
                      <Switch.Label className="switch-label font-semibold">
                        Statistics
                      </Switch.Label>
                      <Switch
                        checked={consent.statistics}
                        onChange={(e) =>
                          handleConsentChange("statistics", !consent.statistics)
                        }
                        className={`${
                          consent.statistics ? "bg-blue-600" : "bg-gray-200"
                        } relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none`}
                      >
                        <span className="sr-only">Statistics</span>
                        <span
                          className={`${
                            consent.statistics
                              ? "translate-x-6"
                              : "translate-x-1"
                          } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                      </Switch>
                    </Switch.Group>
                    <Switch.Group as="div" className="switch-group">
                      <Switch.Label className="switch-label font-semibold">
                        Advertising
                      </Switch.Label>
                      <Switch
                        checked={consent.advertising}
                        onChange={(e) =>
                          handleConsentChange(
                            "advertising",
                            !consent.advertising
                          )
                        }
                        className={`${
                          consent.advertising ? "bg-blue-600" : "bg-gray-200"
                        } relative inline-flex items-center h-6 rounded-full w-11 focus:outline-none`}
                      >
                        <span className="sr-only">Advertising</span>
                        <span
                          className={`${
                            consent.advertising
                              ? "translate-x-6"
                              : "translate-x-1"
                          } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                      </Switch>
                    </Switch.Group>
                  </div>
                </div>
                <div className="m-4">
                  <button onClick={accept} className="primary-button">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieBanner;
