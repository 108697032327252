import React from "react";

import "../styles/copy-right.css";

const Copyright = () => {
  return (
    <div className="copyright-section">
      <p>
        &copy; {new Date().getFullYear()} Choose Your Country. All rights
        reserved.
      </p>
    </div>
  );
};

export default Copyright;
