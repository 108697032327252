import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

import "../styles/privacy_policy.css";
import Privacy from "./utils/Privacy";

const PrivacyPolicy = ({ showModal, closeModal }) => {
  const openInNewTab = () => {
    window.open("/privacy-policy", "_blank");
  };

  return (
    <div
      id="default-modal"
      className={`fixed top-0 right-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50 ${
        showModal ? "" : "hidden"
      }`}
    >
      <div className="modal-container">
        <div className="title-container">
          <h3 className="title">Privacy Policy</h3>
          <div>
            <button onClick={openInNewTab} className="b-open-new-tab mr-4">
              <FaExternalLinkAlt />
            </button>
            <button onClick={closeModal} className="b-above-to-close">
              <svg
                className="w-5 h-5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6M6 6L18 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="text-container">
          <Privacy />
          <div className="close-container">
            <button onClick={closeModal} className="b-below-to-close">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
