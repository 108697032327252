import React from "react";

import Contact from "../components/Contact";
import HeroSection from "../components/HeroSection";
import About from "../components/About";
import MainSection from "../components/MainSection";

const Home = ({system}) => {
  return (
    <div>
      <HeroSection system={system} />
      <MainSection />
      <About system={system} id="about" />
      <Contact id="contact" />
    </div>
  );
};

export default Home;
