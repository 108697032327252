import React from "react";
import { Link } from "react-router-dom";

import "../styles/card.css";

const Cards = ({ card }) => {
  return (
    <div className="cardstyle">
      <Link to="/tops" state={{ card: card }}>
        <figure className="px-10 pt-10">
          <img alt="logo card" src={card.image} className="rounded-xl" />
        </figure>
        <div className="card-body items-center text-center">
          <h3 className="card-name">{card.display_name}</h3>
        </div>
      </Link>
    </div>
  );
};

export default Cards;
