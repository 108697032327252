import React from "react";

import "../styles/sidebar.css";

const Sidebar = (props) => {
  return (
    <div className="sidebar">
      <div className="border-l-4 border-yellow-500 h-full pl-2">
        {props.children}
      </div>
    </div>
  );
};

export default Sidebar;
