import React from "react";

import DonationButtons from "../utils/DonationButtons";

const SidebarDonations = () => {
  return (
    <div className="center-element">
      <h2 className="text-lg font-semibold my-3">Donations</h2>
      <div className="px-8">
        <DonationButtons />
      </div>
    </div>
  );
};

export default SidebarDonations;
