import React from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";

import TablePagination from "./TablePagination";
import TableHeader from "./TableHeader";
import "../../styles/base_table.css";

const BaseTable = ({ data, text }) => {
  const itemsPerPage = 15;

  const columns = React.useMemo(
    () =>
      data.length > 0
        ? Object.keys(data[0]).map((key) => ({ accessor: key, Header: key }))
        : [],
    [data]
  );

  columns.unshift({
    Header: "Ranking",
    accessor: (row, index) => index + 1,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: itemsPerPage },
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  return (
    <div className="table-containter">
      <>
        <div className="my-4">
          <TableHeader
            text={text}
            filter={globalFilter}
            setFilter={setGlobalFilter}
          />
          <TablePagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
          />
        </div>

        <table {...getTableProps()} className="table-auto w-full">
          <thead className="bg-gray-100">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="table-active"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="p-4 text-center">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="table-content">
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="my-4">
          <TablePagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
          />
        </div>
      </>
    </div>
  );
};

export default BaseTable;
