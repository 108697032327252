import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation } from "react-router-dom";

import "../styles/nav_bar.css";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [navigation, setNavigation] = useState([
    { name: "Home", href: "/", current: false },
    { name: "Country Comparison", href: "/countrycomparison", current: false },
    { name: "Country statistics", href: "/countrystatistics", current: false },
    { name: "Info", href: "/info", current: false },
    { name: "Contact", href: "/contact", current: false },
  ]);

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const updatedNavigation = navigation.map((item) => ({
      ...item,
      current: item.href === location.pathname,
    }));
    setNavigation(updatedNavigation);
  }, [location.pathname]);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <div className={scrolled ? "navbar-fixed" : ""}>
      <Disclosure as="nav" className="main-green-background">
        {({ open }) => (
          <>
            <div className="nav-container">
              <div className="nav-buttons-container">
                <div className="mobile-menu-button">
                  <Disclosure.Button className="close-mobile-menu">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon
                        className="close-mobile-menu-size"
                        aria-hidden="true"
                      />
                    ) : (
                      <Bars3Icon
                        className="close-mobile-menu-size"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="center-nav-left-button">
                  <div className="company-button">
                    <NavLink to="/">
                      <img
                        className="mobile-company-button"
                        src="images/Logo_Choose_your_contry.png"
                        alt="Your Company"
                      />
                    </NavLink>
                    <NavLink to="/">
                      <img
                        className="desktop-company-button"
                        src="images/Logo_Choose_your_contry.png"
                        alt="Your Company"
                      />
                    </NavLink>
                  </div>
                  <div className="buttons-logo-separation">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          activeclassname="nav-button-selected"
                          className={classNames(
                            item.current
                              ? "nav-button-selected"
                              : "nav-default-button",
                            "nav-buttons"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    activeclassname="nav-button-selected"
                    className={classNames(
                      item.current
                        ? "nav-button-selected"
                        : "nav-default-button",
                      "mobile-nav-buttons"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="yellow-bar"></div>
    </div>
  );
};

export default Navbar;
