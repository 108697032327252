import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import { toast } from "react-hot-toast";

import "../styles/contact.css";
import { sendEmail } from "../services/SystemService";
import PrivacyPolicy from "./PrivacyPolicy";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Contact = () => {
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [agreed, setAgreed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !lastname || !email || !message) {
      toast.error("Please complete all required fields.");
      return;
    }

    if (!agreed) {
      toast.error("You must accept the privacy policy.");
      return;
    }

    try {
      await sendEmail({
        name: name,
        lastname: lastname,
        email: email,
        message: message,
      });
      setName("");
      setLastname("");
      setEmail("");
      setMessage("");
      setAgreed(false);

      toast.success("Email sent successfully");
    } catch (error) {
      toast.error("Failed to send email. Please try again!");
      return;
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div id="contact" className="contact-space min-h-screen">
      <div className="contact-header">
        <h2 className="contact-title my-8">Contact us</h2>
      </div>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-grid-contact">
          <div>
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <div className="form-input-space">
              <input
                type="text"
                name="name"
                id="name"
                autoComplete="given-name"
                className="form-input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="lastname" className="form-label">
              Last name
            </label>
            <div className="form-input-space">
              <input
                type="text"
                name="lastname"
                id="lastname"
                autoComplete="given-name"
                className="form-input"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-input-column">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <div className="form-input-space">
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                className="form-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form-input-column">
            <label htmlFor="message" className="form-label">
              Message
            </label>
            <div className="form-input-space">
              <textarea
                name="message"
                id="message"
                rows={4}
                className="form-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
          </div>
          <Switch.Group as="div" className="switch-group">
            <div className="switch-place">
              <Switch
                checked={agreed}
                onChange={setAgreed}
                className={classNames(
                  agreed ? "bg-indigo-600" : "bg-gray-200",
                  "switch"
                )}
              >
                <span className="sr-only">Agree to policies</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    agreed ? "translate-x-3.5" : "translate-x-0",
                    "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </div>
            <Switch.Label className="switch-label">
              By selecting this, you agree to our{" "}
              <a href="#" className="switch-label-link" onClick={toggleModal}>
                privacy&nbsp;policy
              </a>
              .
            </Switch.Label>
          </Switch.Group>
        </div>
        <div className="mt-10">
          <button type="submit" className="primary-button block w-full">
            SEND IT!
          </button>
        </div>
      </form>
      <PrivacyPolicy showModal={showModal} closeModal={toggleModal} />
    </div>
  );
};

export default Contact;
