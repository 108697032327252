import React from "react";
import "../../styles/spinner.css";

const Spinner = ({ text }) => {
  return (
    <div className="spinner-container">
      <span className="loading loading-ring loading-md"></span>
      <span className="spinner-text">{text}</span>
    </div>
  );
};

export default Spinner;
