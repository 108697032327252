import React, { useEffect } from "react";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { Tooltip } from "react-tooltip";

import TablePagination from "./TablePagination";
import GlobalFilter from "./GlobalFilter";
import "../../styles/base_table.css";

const ComparisonTable = ({ data, text }) => {
  const itemsPerPage = 20;
  const attributes = Object.keys(data[Object.keys(data)[0]]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Attributes",
        accessor: "atributo",
        Cell: ({ row }) => {
          return (
            <div>
              <div
                data-tooltip-id={row.values.atributo}
                data-tooltip-content={row.original.description}
                data-tooltip-variant="dark"
              >
                {row.values.atributo}
              </div>
              <Tooltip
                id={row.values.atributo}
                place="bottom"
                className="custom-tooltip"
              />
            </div>
          );
        },
      },
      ...Object.keys(data).map((country) => ({
        Header: country,
        accessor: country,
        Cell: ({ row }) => row.values[country],
      })),
    ],
    [data]
  );

  const rows = React.useMemo(
    () =>
      attributes.map((attribute) => ({
        atributo: attribute,
        description: data[Object.keys(data)[0]][attribute].description,
        ...Object.keys(data).reduce((acc, country) => {
          const value = data[country][attribute]["value"];
          const character = data[country][attribute]["character"];
          acc[country] =
            (value && value !== "nan" ? value : "") + 
            (value && value !== "nan" && character && character !== "nan" ? " " + character : "");
          return acc;
        }, {}),
      })),
    [data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    pageCount,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: rows,
      initialState: { pageSize: itemsPerPage },
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  useEffect(() => {
    return () => {
      if (Tooltip.rebuild) {
        Tooltip.rebuild();
      }
    };
  }, []);

  return (
    <div className="table-container">
      <div className="table-header">
        <div></div>
        <div className="col-span-1 justify-self-center">
          <TablePagination
            pageIndex={pageIndex}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
          />
        </div>
        <div className="input-filter">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </div>
      </div>

      <div className="table-scroll">
        <table {...getTableProps()} className="table-auto w-full ">
          <thead className="bg-gray-100">
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="table-active"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className="p-4 text-center">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} className="table-content">
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="my-4">
        <TablePagination
          pageIndex={pageIndex}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
        />
      </div>
    </div>
  );
};

export default ComparisonTable;
