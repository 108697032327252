import React, { useEffect } from "react";

export function addGoogleAnalytics() {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName("head")[0];
    const scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`;
    scriptElement.onload = () => {
      resolve(true);
    };
    head.appendChild(scriptElement);
  });
}

export function initializeGoogleAnalytics() {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag("js", new Date());
  window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
    anonymize_ip: true,
    allow_google_signals: true,
  });
  const pagePath = window.location
    ? window.location.pathname + window.location.search + window.location.hash
    : undefined;
  window.gtag("event", "page_view", { page_path: pagePath });
}

export function useTrackGoogleAnalytics() {
  useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag('event', 'page_view', { page_path: url });
    };

    const unlisten = () => {
      window.removeEventListener('hashchange', handleRouteChange);
      window.removeEventListener('popstate', handleRouteChange);
    };

    window.addEventListener('hashchange', handleRouteChange);
    window.addEventListener('popstate', handleRouteChange);

    return unlisten;
  }, []);
}

export function enableGoogleAnalytics() {
  addGoogleAnalytics().then((status) => {
    if (status) {
      initializeGoogleAnalytics();
    }
  });
}

export function trackEventGoogleAnalytics(params) {
  window.gtag("send", "event", "consent", true);
}

export function enableGoogleAdsense() {
  const head = document.getElementsByTagName("head")[0];
  const scriptElement = document.createElement(`script`);
  scriptElement.type = `text/javascript`;
  scriptElement.async = true;
  scriptElement.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.REACT_APP_GOOGLE_ADSENSE_ID}`;
  scriptElement.crossOrigin = "anonymous";
  head.appendChild(scriptElement);
}

export function GoogleAdsenseContainer({ client, slot }) {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div style={{ textAlign: "left", overflow: "hidden" }}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
}
