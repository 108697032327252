import React from "react";
import { FaSadTear } from "react-icons/fa";

import "../styles/NotFound.css";

function NotFound() {
  return (
    <div className="not-found min-h-screen">
      <h1>404 - Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
      <div className="sad-icon">
        <FaSadTear />
      </div>
    </div>
  );
}

export default NotFound;
