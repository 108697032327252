import React, { useState, useEffect } from "react";
import "../styles/footer.css";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiesPolicy from "./CookiesPolicy";
import DonationButtons from "./utils/DonationButtons";
import Copyright from "./Copyright";

const Footer = () => {
  const [showModal, setShowModal] = useState(false);
  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const [showDonationPrompt, setShowDonationPrompt] = useState(false);
  const messageTime = 15 * 60 * 1000;

  const showDonationMessage = () => {
    setShowDonationPrompt(true);
  };

  const resetTimer = () => {
    setShowDonationPrompt(false);
    setTimeout(showDonationMessage, messageTime);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleCookieModal = () => {
    setShowCookiesModal(!showCookiesModal);
  };

  const handleCloseButtonClick = () => {
    setShowDonationPrompt(false);
    resetTimer();
  };

  useEffect(() => {
    const initialTimerId = setTimeout(showDonationMessage, messageTime);
    return () => clearTimeout(initialTimerId);
  }, [messageTime]);

  return (
    <div>
      <div className="yellow-bar"></div>
      <footer className="footer-section">
        <div className="center-element">
          <span className="footer-title">Company</span>
          <a href="about" className="link link-hover">
            About us
          </a>
          <a href="contact" className="link link-hover">
            Contact
          </a>
        </div>

        <div className="center-element">
          <span className="footer-title">Legal</span>
          <a href="#" className="link link-hover" onClick={toggleModal}>
            Privacy&nbsp;policy
          </a>
          {showModal && (
            <PrivacyPolicy showModal={showModal} closeModal={toggleModal} />
          )}
          <a href="#" className="link link-hover" onClick={toggleCookieModal}>
            Cookies&nbsp;policy
          </a>
          {showCookiesModal && (
            <CookiesPolicy
              showModal={showCookiesModal}
              closeModal={toggleCookieModal}
            />
          )}
        </div>

        <div className="center-element">
          <span className="footer-title">Donations</span>
          <DonationButtons />
        </div>
      </footer>
      {showDonationPrompt && (
        <div className="donation-modal-container" onClick={resetTimer}>
          <div className="donation-prompt-modal-content">
            <h2 className="donation-title-container">Consider a donation!</h2>
            <p className="donation-text-container">
              Thank you for spending time on our page! Sorry for the
              interruption, if you like our work, please consider making a
              contribution to help us improve as we provide this tool for free
              and it helps us defray its costs.
            </p>
            <div className="center-element">
              <span className="footer-title ">Donations</span>
              <DonationButtons />
            </div>
            <div className="close-container">
              <button
                onClick={handleCloseButtonClick}
                className="b-below-to-close"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
      <Copyright />
    </div>
  );
};

export default Footer;
