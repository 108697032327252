import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";

import BaseTable from "../components/utils/BaseTable";
import { getTops } from "../services/TopServices";
import Spinner from "../components/utils/Spinner"; 
import "../styles/NotFound.css";

const Tops = () => {
  const location = useLocation();
  const card = location.state?.card;
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); 

  useEffect(() => {
    if (!card) {
      return;
    }

    const fetchTableData = async () => {
      setIsLoading(true);
      try {
        const response = await getTops(card.card_top);
        setTableData(response.data);
      } catch (error) {
        toast.error("ERROR! Please try again!");
      } finally {
        setIsLoading(false);
      }
    };

    fetchTableData();
  }, [card]);

  if (!card) {
    return (
      <div className="not-found min-h-screen">
        <p>Error: You must select a card before accessing /tops</p>
      </div>
    );
  }

  return (
    <div className="table-scroll">
      {isLoading ? ( 
        <Spinner text="Processing..." />
      ) : (
        <BaseTable data={tableData} text={card.display_name} />
      )}
    </div>
  );
};

export default Tops;
