import React from "react";
import { GiCoffeeCup } from "react-icons/gi";
import { FaPaypal } from "react-icons/fa";

const DonationButtons = () => {
  return (
    <div className="flex flex-col">
      <a
        href="https://www.buymeacoffee.com/chooseyourcountry"
        target="_blank"
        rel="noreferrer"
        className="primary-button my-1"
      >
        Invite me a coffee <GiCoffeeCup className="coffee" />
      </a>
      <a
        href="https://www.paypal.com/paypalme/chooseyourcountry?country.x=ES&locale.x=es_ES"
        target="_blank"
        rel="noreferrer"
        className="primary-button my-1"
      >
        PayPal <FaPaypal className="paypal" />
      </a>
    </div>
  );
};

export default DonationButtons;
