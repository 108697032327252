import React from "react";

import Cookies from "../components/utils/Cookies";

const CookiesPolicy = () => {
  return (
    <Cookies />
  );
};

export default CookiesPolicy;
