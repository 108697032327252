import Select from "react-select";

const CustomSelect = ({ id, options, value, onChange, label }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#23a07b",
      borderRadius: 6,
      textColor: "white",
      "&:hover": {
        borderColor: "yellow", // Border color on hover
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#23a07b" : "white",
      color: state.isFocused ? "white" : "#333",
      borderRadius: 6,
      ":active": {
        backgroundColor: "#23a07b", // Color when clicked (before selecting)
      },
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "white",
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: 6,
      "&::-webkit-scrollbar": {
        width: "10px", // Scroll Width
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888", // Scroll Color
        borderRadius: "5px", // Scroll Borders
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#555",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "white",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        color: "#666", // Border color on hover
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      color: "white",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  return (
    <div className="">
      <Select
        id={id}
        options={options}
        styles={customStyles}
        value={options.find((option) => option.value === value)}
        onChange={(selectedOption) => onChange(selectedOption.value)}
        placeholder={label}
      />
    </div>
  );
};

export default CustomSelect;
