import React, { useState, useEffect } from "react";
import Papa from "papaparse";

import "../../styles/base_table.css";

const CookieTable = ({tableFiles, titles}) => {
  const [tables, setTables] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedTables = await Promise.all(
          tableFiles.map(async (file) => {
            const response = await fetch(`/data/${file}`);
            const text = await response.text();
            const result = Papa.parse(text, { header: true });
            return result.data;
          })
        );
        setTables(fetchedTables);
      } catch (error) {
        console.error("Error fetching tables:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {tables.map((table, index) => (
        <div key={index} className="pt-4">
          <p>
            <strong>{titles[index]}</strong>
          </p>
          <table className="table-auto w-full">
            <thead className="bg-gray-100">
              <tr className="table-active">
                <th className="p-4 text-center">Property</th>
                <th className="p-4 text-center">Cookie</th>
                <th className="p-4 text-center">Purpose</th>
                <th className="p-4 text-center">Term</th>
              </tr>
            </thead>
            <tbody>
              {table.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="table-content">{row.property}</td>
                  <td className="table-content">{row.cookie}</td>
                  <td className="table-content">{row.purpose}</td>
                  <td className="table-content">{row.term}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default CookieTable;
