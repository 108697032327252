import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-hot-toast";

const httpService = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
});

const getTokenFromCookie = () =>
  Cookies.get(process.env.REACT_APP_TOKEN_COOKIE_NAME);
const saveTokenToCookie = (token) =>
  Cookies.set(process.env.REACT_APP_TOKEN_COOKIE_NAME, token, { expires: 1 });

const getClientIP = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    return null;
  }
};

export const initToken = async () => {
  if (!getTokenFromCookie()) {
    getNewAccessToken();
  }
};

const getNewAccessToken = async () => {
  try {
    const { data } = await httpService.post("token/", {
      email: process.env.REACT_APP_ADMIN_EMAIL,
      password: process.env.REACT_APP_ADMIN_PASSWORD,
    });

    const { access, refresh } = data;
    saveTokenToCookie(access);
    return { access, refresh };
  } catch (error) {
    toast.error("ERROR! Please try again!");
  }
};

httpService.interceptors.request.use(async (config) => {
  const token = getTokenFromCookie();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  const clientIP = await getClientIP();
  if (clientIP) {
    config.headers["X-Forwarded-For"] = clientIP;
  }
  return config;
});

httpService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await getNewAccessToken();
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return httpService(originalRequest);
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error.response.data);
  }
);

export default httpService;
