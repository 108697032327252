import React from "react";

import "../styles/about.css";

const stats = [
  { name: "Countries", value: "+165" },
  { name: "Indicators", value: "+2000" },
  { name: "Data updated up to", value: "2022" },
  { name: "Definitions", value: "+100" },
];

const About = ({ system }) => {
  return (
    <div
      id="about"
      className="relative isolate overflow-hidden py-12 min-h-screen"
    >
      <div className="about-background" aria-hidden="true">
        <div className="about-shadows" />
      </div>
      <div className="about-background2" aria-hidden="true">
        <div className="about-shadows" />
      </div>
      <div className="about-horizontal">
        <img src=".//images/about.png" alt="about" />
        <div className="about-section">
          <div className="about-description">
            <h2 className="about-title">About {system ? system.name : ""}</h2>
            <p className="about-subtitle">{system ? system.about : ""}</p>
          </div>
        </div>
      </div>
      <div className="about-extras">
        <dl className="about-info">
          {stats.map((stat) => (
            <div key={stat.name} className="flex flex-col-reverse">
              <dt className="about-info-text">{stat.name}</dt>
              <dd className="about-info-value">{stat.value}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default About;
