import httpService from "./http_service";

export function getCountryComparison(country1, country2, years) {
  const endpoint = `countries/get_countries_data/?countries=${country1}&countries=${country2}&years_ago=${years}`;
  return httpService.get(endpoint);
}
export function getCountries() {
  const endpoint = `countries`;
  return httpService.get(endpoint);
}
