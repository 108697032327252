import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { toast } from "react-hot-toast";
import { Routes, Route, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

import "./styles/App.css";
import Home from "./screens/Home";
import Tops from "./screens/Tops";
import CountryComparison from "./screens/CountryComparison";
import CountryStatistics from "./screens/CountryStatistics";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import CookiesPolicy from "./screens/CookiesPolicy";
import Info from "./screens/Info";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import About from "./components/About";
import CookieBanner from "./components/utils/CookieBanner";
import Contact from "./components/Contact";
import Sidebar from "./components/Sidebar";
import SidebarCards from "./components/sidebar-components/SidebarCards";
import SidebarDonations from "./components/sidebar-components/SidebarDonations";
import NotFound from "./components/NotFound";
import { initToken } from "./services/http_service";
import { getSystem } from "./services/SystemService";
import {
  enableGoogleAnalytics,
  enableGoogleAdsense,
} from "../src/components/utils/GoogleServices";

function App() {
  const [system, setSystem] = useState();

  useEffect(() => {
    getSystem()
      .then((response) => {
        setSystem(response.data[0]);
      })
      .catch((error) => {
        toast.error("ERROR! Please try again!");
      });
  }, []);

  useEffect(() => {
    initToken();
  }, []);

  useEffect(() => {
    const cookieValue = Cookies.get("consent");
    if (cookieValue) {
      const consent = JSON.parse(cookieValue);
      if (consent.statistics) {
        enableGoogleAnalytics();
      }
      if (consent.advertising) {
        enableGoogleAdsense();
      }
    }
  }, []);

  return (
    <div className="App flex flex-col min-h-screen">
      <header>
        <CookieBanner />
      </header>
      <NavBar />
      <Toaster className="rounded-md" />
      <Routes>
        <Route path="/*" element={<MainLayout system={system} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

function MainLayout({ system }) {
  const location = useLocation();
  const path = location.pathname.replace(/\/$/, "");

  const isSidebarVisible =
    path === "/tops" ||
    path === "/countrycomparison" ||
    path === "/countrystatistics";

  return (
    <div className="display-sidebar flex-sidebar min-h-screen">
      <div className="flex-1">
        <Routes>
          <Route path="/" element={<Home system={system} />} />
          <Route path="/about" element={<About system={system} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          {isSidebarVisible && (
            <>
              <Route path="/tops" element={<Tops />} />
              <Route
                path="/countrycomparison"
                element={<CountryComparison />}
              />
              <Route
                path="/countrystatistics"
                element={<CountryStatistics />}
              />
            </>
          )}
          <Route path="/info" element={<Info />} />
        </Routes>
      </div>

      {isSidebarVisible && (
        <div className="sidebar-container">
          <Sidebar>
            <SidebarCards />
            <SidebarDonations />
          </Sidebar>
        </div>
      )}
    </div>
  );
}

export default App;
