import React from "react";
import { Link } from "react-router-dom";

import "../styles/hero_section.css";

const HeroSection = ({ system }) => {
  return (
    <div className="hero-section">
      <div className="hero-overlay bg-white"></div>
      <div className="hero-horizontal">
        <img
          src="/images/Choose your place to live.png"
          alt="Choose Your Country logo"
        />
        <div className="hero-text">
          <h6 className="hero-title">
            Discover the world and find your perfect home with
          </h6>
          <h1 className="hero-title mb-5">
            {system ? system.name : "Choose Your Country"}!
          </h1>
          <Link to="/countrycomparison" className="primary-button">
            Start now
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
