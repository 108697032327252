import React from "react";

import Privacy from "../components/utils/Privacy";

const PrivacyPolicy = () => {
  return (
    <Privacy />
  );
};

export default PrivacyPolicy;
