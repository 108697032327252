import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-hot-toast";

import "../styles/info.css";
import { getDictionary } from "../services/DictionaryServices";

function Info() {
  const [activeAccordion, setActiveAccordion] = useState([]);
  const [dictionaryData, setDictionaryData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDictionaryData, setFilteredDictionaryData] = useState([]);

  useEffect(() => {
    loadDictionaryData();
  }, []);

  useEffect(() => {
    const filteredData = dictionaryData.filter((entry) =>
      entry.word.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDictionaryData(filteredData);
  }, [dictionaryData, searchTerm]);

  const getDefinitionsByLetter = useCallback(
    (letter) => {
      const lowerCaseLetter = letter.toLowerCase();
      return filteredDictionaryData
        .filter(
          (entry) =>
            entry.word && entry.word.charAt(0).toLowerCase() === lowerCaseLetter
        )
        .map(({ word, definition }) => ({ word, definition }));
    },
    [filteredDictionaryData]
  );

  const alphabet = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";

  const accordionData = alphabet.split("").map((letter, index) => ({
    title: letter,
    entries: getDefinitionsByLetter(letter),
  }));

  useEffect(() => {
    var indexes = accordionData
      .map((item, index) => (item.entries.length > 0 ? index : null))
      .filter((index) => index !== null);
    if (filteredDictionaryData.length === dictionaryData.length) {
      indexes = [];
    }
    setActiveAccordion(indexes);
  }, [filteredDictionaryData]);

  const loadDictionaryData = async () => {
    try {
      const response = await getDictionary();
      setDictionaryData(response.data);
    } catch (error) {
      toast.error("ERROR! Please try again!");
    }
  };

  const toggleAccordion = useCallback((index) => {
    setActiveAccordion((prevIndexes) =>
      prevIndexes.includes(index)
        ? prevIndexes.filter((prevIndex) => prevIndex !== index)
        : [...prevIndexes, index]
    );
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="main-section min-h-screen my-8">
      <div>
        <h2 className="info-title">Information</h2>
        <p className="info-paragraph">
          In this section, you will find a dictionary explaining key terms to
          better understand the terminology used on the page.
        </p>
      </div>
      <div className="flex info-container">
        <div className="left-content">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search..."
            className="search-input"
          />
          <div id="sidebar" className="sidebar">
            <h3>Dictionary</h3>
            <ul>
              {accordionData.map((item, index) => (
                <li
                  key={index}
                  className="text-gray-600 cursor-pointer hover:text-gray-900"
                  onClick={() => toggleAccordion(index)}
                >
                  {item.title}
                  {activeAccordion.includes(index) && (
                    <ul onClick={(e) => e.stopPropagation()}>
                      {item.entries.map(({ word }, entryIndex) => (
                        <li key={entryIndex} className="hover:text-gray-500">
                          {word}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="right-column">
          {accordionData.map((item, index) => (
            <div key={index}>
              <h2 id={`accordion-collapse-heading-${index}`}>
                <button
                  type="button"
                  className={`flex items-center justify-between w-full p-5 font-medium text-left text-gray-600 hover:bg-gray-100 rounded-xl ${
                    activeAccordion.includes(index)
                      ? "focus:ring-4 focus:ring-gray-200  dark:border-gray-700 dark:text-gray-400 dark:hover-bg-gray-800"
                      : ""
                  }`}
                  data-accordion-target={`#accordion-collapse-body-${index}`}
                  aria-expanded={activeAccordion.includes(index)}
                  aria-controls={`accordion-collapse-body-${index}`}
                  onClick={() => toggleAccordion(index)}
                >
                  <span>{item.title}</span>
                  <svg
                    data-accordion-icon
                    className="w-3 h-3 rotate-180 shrink-0"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              <div
                id={`accordion-collapse-body-${index}`}
                className={`${
                  activeAccordion.includes(index) ? "block" : "hidden"
                }`}
                aria-labelledby={`accordion-collapse-heading-${index}`}
              >
                {item.entries.length > 0 ? (
                  item.entries.map(({ word, definition }, entryIndex) => (
                    <div key={entryIndex} className="mb-4">
                      <p className="text-black font-bold">{word}</p>
                      <p className="text-gray-600 dark:text-gray-700 text-sm">
                        {definition}
                      </p>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-600 dark:text-gray-700">
                    No words with the letter {item.title}.
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Info;
