import React, { useState, useEffect } from "react";
import Spinner from "../components/utils/Spinner";
import { toast } from "react-hot-toast";
import { Tooltip } from "react-tooltip";

import {
  getCountries,
  getCountryComparison,
} from "../services/CountryServices";
import "../styles/country_comparison.css";
import ComparisonTable from "../components/utils/ComparisonTable";
import CustomSelect from "../components/utils/CustomSelect";

const CountryComparison = () => {
  const [selectedCountry1, setSelectedCountry1] = useState("");
  const [selectedCountry2, setSelectedCountry2] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [comparisonData, setComparisonData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const years = [
    { value: "0", label: "Last year updated (2022)" },
    { value: "5", label: "From 2022 to 2017" },
    { value: "10", label: "From 2022 to 2012" },
    { value: "20", label: "From 2022 to 2002" },
  ];

  useEffect(() => {
    getCountries()
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        toast.error("ERROR! Please try again!");
      });
  }, []);

  const handleCompare = () => {
    if (
      selectedCountry1 !== "" &&
      selectedCountry2 !== "" &&
      !isLoading &&
      selectedCountry1 !== selectedCountry2
    ) {
      setIsLoading(true);
      var yearsAgo = selectedYear ? selectedYear : 0;
      getCountryComparison(selectedCountry1, selectedCountry2, yearsAgo)
        .then((response) => {
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            setComparisonData(response.data);
          }
        })
        .catch((error) => {
          toast.error("ERROR! Please try again!");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (selectedCountry1 === "" || selectedCountry2 === "") {
      toast.error("You must select 2 countries to compare.");
    } else if (selectedCountry1 === selectedCountry2) {
      toast.error("It's not possible to compare the same country.");
    }
  };

  return (
    <div>
      <div className="flex-1 container-padding min-h-screen my-8">
        <div id="countrycomparison" className="nav-container">
          <h1 className="contry-comparison-title ">Country Comparison</h1>
          <div className="mt-8">
            <div className="form-grid">
              <CustomSelect
                id="country1"
                options={countries.map((country) => ({
                  value: country.country,
                  label: country.name,
                }))}
                value={selectedCountry1}
                onChange={setSelectedCountry1}
                label="Select a country"
              />
              <CustomSelect
                id="country2"
                options={countries.map((country) => ({
                  value: country.country,
                  label: country.name,
                }))}
                value={selectedCountry2}
                onChange={setSelectedCountry2}
                label="Select a country"
              />
            </div>
            <div className="flex items-center justify-center mt-4">
              <CustomSelect
                id="years"
                options={years.map((year) => ({
                  value: year.value,
                  label: year.label,
                }))}
                value={selectedYear}
                onChange={setSelectedYear}
                label="Select years between"
              />
            </div>
            <button
              data-tooltip-id="compare-tool"
              data-tooltip-content="The values are an average between the selected years"
              data-tooltip-variant="dark"
              className="primary-button mt-4 my-4 relative"
              onClick={handleCompare}
              style={{
                cursor: isLoading ? "not-allowed" : "pointer",
                pointerEvents: isLoading ? "none !important" : "auto",
              }}
            >
              {isLoading ? <Spinner text="Processing..." /> : "Comparar"}
            </button>
            <Tooltip id="compare-tool" place="right" />
          </div>

          {Object.keys(comparisonData).length > 0 && (
            <div className="centered-table">
              <ComparisonTable data={comparisonData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountryComparison;
