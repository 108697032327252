import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

import Cards from "../components/Cards";
import { getCards } from "../services/CardServices";
import Spinner from "../components/utils/Spinner"; 
import "../styles/country_statistics.css";
import TablePagination from "../components/utils/TablePagination";
import GlobalFilter from "../components/utils/GlobalFilter";


const CountryStatistics = () => {
  const [cards, setCards] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true); 
  const cardsPerPage = 12;

  useEffect(() => {
    getCards()
      .then((response) => {
        setCards(response.data);
      })
      .catch((error) => {
        toast.error("ERROR! Please try again!");
      })
      .finally(() => {
        setIsLoading(false); 
      });
  }, []);

  const nextPage = () => {
    if (currentPage < Math.ceil(cards.length / cardsPerPage) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const gotoPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageCount = Math.ceil(cards.length / cardsPerPage);

  const filteredCards = cards.filter((card) => {
    const cardDisplayName = card.display_name || "";
    return cardDisplayName.toLowerCase().includes(filter.toLowerCase());
  });

  const indexOfLastCard = (currentPage + 1) * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredCards.slice(indexOfFirstCard, indexOfLastCard);

  return (
    <div>
      {isLoading ? ( 
        <Spinner className="spinner" text="Loading Cards..." />
      ) : (
        <div className="main-section min-h-screen my-8">
          <div className="flex flex-col items-center justify-center sm:flex-row sm:items-center">
            <h2 id="cards" className="main-title">
              Country statistics
            </h2>
            <div className="search-container mt-2 sm:mt-0">
              <GlobalFilter filter={filter} setFilter={setFilter} />
            </div>
          </div>
          <div href="table" className="main-section-grid my-12">
            {currentCards.map((card, index) => (
              <Cards key={index} card={card} />
            ))}
          </div>
          <TablePagination
            pageIndex={currentPage}
            pageCount={pageCount}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
            canPreviousPage={currentPage > 0}
            canNextPage={currentPage < pageCount - 1}
          />
        </div>
      )}
    </div>
  );
};

export default CountryStatistics;
